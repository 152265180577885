<template>
  <el-card class="v_user_cas_out_list">
    <div class="g-flex"  slot="header">
      <span>任务统计</span>
      <!-- <div class="g-flex-justify-end g-flex-1">
        <el-button size="mini" type="success" @click="exportClick">导出列表</el-button>
      </div> -->
    </div>
    <!-- 搜索 -->
    <el-form :inline="true" size="mini" :model="query">
      <el-form-item label="时间：">
          <el-date-picker v-model="query.date_time" @change="getList()" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" > </el-date-picker>
      </el-form-item>  
      <el-button type="info" size="mini" @click="resetData">重置</el-button>
      <el-button type="primary" size="mini"  @click="getList()">查询</el-button>
    </el-form>

    <!-- 表格 -->
    <el-table size="small" :row-class-name="tableRowToalClassName"  height="700" v-loading="table.loading" :data="table.rows" border>
      <el-table-column label="日期"  :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{ scope.row.date }}</span>
        </template>
      </el-table-column>

       <el-table-column label="订单总发单数" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{ scope.row.taskOrderRelease }}</span>
        </template>
      </el-table-column>

      <el-table-column label="订单已完成数" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{ scope.row.taskOrderEnd }}</span>
        </template>
      </el-table-column>

      <el-table-column label="商家充值总金额" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{ scope.row.recharge }}</span>
        </template>
      </el-table-column>
      <el-table-column label="本金总提现金额" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{ scope.row.bj_cash }}</span>
        </template>
      </el-table-column>
      <el-table-column label="佣金总提现金额" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{ scope.row.yj_cash }}</span>
        </template>
      </el-table-column>
      <el-table-column label="净利润" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{ scope.row.profit }}</span>
        </template>
      </el-table-column>
      
      <el-table-column label="操作" width="100" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="setForm(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 查看商家弹窗 -->
    <total-manage-task-pop ref="TotalManageTaskPop"/>

    <!-- 导出弹窗 -->
    <total-manage-task-export-pop ref="TotalManageTaskExportPop"/>
  </el-card>
</template>

<script>
  import { formatDate } from '@/utils/index.js';
  import { apiCashSuccess, apiTaskTotalGetList } from '@/utils/api.js'
  import TotalManageTaskExportPop from './TotalManageTaskExportPop.vue'
  import TotalManageTaskPop from './TotalManageTaskPop.vue'
  export default {
    components: { TotalManageTaskExportPop, TotalManageTaskPop },
    data() {
      return {
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        auth: {
          addBlack: this.$global.ApiList.includes(60),
          delBlack: this.$global.ApiList.includes(61),
        },

        query: { date_time: [] },

        table:{ loading:false, total:0, rows:[] },

        formData: {
          start_time: '',
          end_time: '',
        },
      }
    },
    created() {
      this.getList()
    },
    methods: {
      formatDate,
      // 统计的类名
      tableRowToalClassName({ row }) {
        if (row.date === '统计') {
          return `total-row`;
        }
        return '';
      },
      // 点击导出
      exportClick() {
        this.$refs.TotalManageTaskExportPop.onShow()
      },

      //提现确认到账
      async apiCashSuccessHandel({ id }) {
        this.$confirm('此操作将会变更为到账成功状态, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.table.loading = true
          const { success, data } = await apiCashSuccess({ id }).catch(() =>  this.table.loading = false)
          this.table.loading = false
          if(!success) return
          this.$message.success(data.msg)
          this.getList()
        }).catch(() => {});
      },

      async getList(pageInit = true) {
        if (pageInit) this.query.page = 1
        this.table.loading = true
        const { success, data } = await apiTaskTotalGetList(this.query).catch(() =>  this.table.loading = false)
        this.table.loading = false
        if(!success) return
        this.table.rows = data.rows
        this.table.total = data.total
      },

      //每页数量改变
      sizeChange(limit) {
        this.query.limit = limit
        this.getList()
      },

      //设置form
      setForm(formData) {
        if(formData.date == '统计') {
          this.formData.start_time = this.table.rows[0].date
          this.formData.end_time = this.table.rows[this.table.rows.length - 2].date
        }else {
          this.formData.start_time = this.formData.end_time = formData.date
        }
        this.$refs.TotalManageTaskPop.onShow(this.formData, status)
      },
      
      // 点击重置
      resetData() {
        this.query = { date_time: [] }
        this.getList()
      }
      
    }
  }
</script>

<style lang="scss">
</style>