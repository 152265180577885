<template>
 <div class="s_user_cash_out_export_pop">
    <el-dialog :title="'店铺统计'" class="s-user-cash-out-dialog" top="15vh" width="800px" :close-on-click-modal="false" :visible.sync="show">
      <el-form label-width="140px" size="mini">
        <!-- 表格 -->
        <el-table size="small"  height="400" v-loading="table.loading" :data="table.rows" border>
          <el-table-column label="店铺ID"  :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span class="g-fsize14">{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="店铺名称"  :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span class="g-fsize14">{{ scope.row.title }}</span>
            </template>
          </el-table-column>
          <el-table-column label="订单总发单数" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span class="g-fsize14">{{ scope.row.taskOrderRelease }}</span>
            </template>
          </el-table-column>

          <el-table-column label="订单已完成数" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span class="g-fsize14">{{ scope.row.taskOrderEnd }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show = false" >取 消</el-button>
      </div>
    </el-dialog>  
 </div>
</template>

<script>
import { apiGetShopReportList } from '@/utils/api.js'
export default {
  data() {
    return {
      show: false,
      query: {
        start_time: '',
        end_time: ''
      },
      table:{ loading:false, rows:[] },
    }
  },
  methods: {
    onShow(data) {
      this.query = Object.assign({}, this.query, data) 
      this.getList()
      this.show = true
    },
    onClose() {
      this.show = false
    },

    async getList() {
      // if (pageInit) this.query.page = 1
      this.table.loading = true
      const { success, data } = await apiGetShopReportList(this.query)
      if(!success) return
      this.table.loading = false
      this.table.rows = data.list
    },
  }
}
</script>

<style lang='scss'>

</style>